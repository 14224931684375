import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import * as XLSX from 'xlsx';
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Backdrop, Button, IconButton, Modal, Tooltip } from "@material-ui/core";
import useDashboard from "../../hooks/useDashboard";
import moment from "moment";
import { ConfirmationNumber, Forward, SaveAlt } from "@material-ui/icons";
import FilterComponent from "../../components/FilterComponent";
import truncateText from "../../utils/truncateText";
import TimelineTicketDetails from "../../components/TimelineTicketDetails";
import UsersSummary from "../../components/UsersSummary";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "transparent",
    margin: "0.5rem",
    padding: "1rem",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 
                0px 4px 10px rgba(0, 0, 0, 0.1)`,
  },

  filterContainer: {
    padding: "1rem",
    backgroundColor: "transparent",
    border: `1px solid #00000020`,
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 
                0px 4px 10px rgba(0, 0, 0, 0.1)`,
    borderRadius: "8px"
  },
  tableContainer: {
    backgroundColor: "transparent",
    margin: "1rem 0",
    padding: 0,
    display: "flex",
    borderRadius: "8px",
    overflow: "auto",
    boxShadow: "none",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    ...theme.scrollbarStyles,
    "& th": {
      color: theme.palette.primary.mainReverseByWhite,
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "500",
      overflow: "none",
      padding: "1rem"
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: "auto",
    maxWidth: "270px",
  },
  exportButton: {
    marginRight: theme.spacing(1),
    width: "25px",
    height: "25px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
    color: theme.palette.primary.mainReverseByWhite,
    borderRadius: "8px"
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  headerText: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontSize: "16px"
  },
  filterItem: {
    width: '100%',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      fontFamily: "Poppins",
      fontSize: "12px",
    },
  },
  reportsTitle: {
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500"
  }
}));

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getReport } = useDashboard();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [queueIds, setQueueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [timeDifference, setTimeDifference] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [usersSummary, setUsersSummary] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [userSummaryOpen, setUserSummaryOpen] = useState(false);
  const handleTicketClick = async (ticketId, page = 1, pageSize = 10, order = isAscending ? "asc" : "desc") => {

    try {
      const response = await api.get(`/timeline/${ticketId}?page=${page}&pageSize=${pageSize}&order=${order}`);
      if (response.data) {
        if (page === 1) {
          setSelectedTicket(response.data);
        } else {
          setSelectedTicket((prevState) => ({
            ...prevState,
            data: [...(prevState?.data || []), ...response.data.data],
          }));
        }
      }

      setModalOpen(true);
    } catch (error) {
      toastError("Erro ao buscar detalhes do ticket:", error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTicket(null);
  };
  const fetchCategories = useCallback(async () => {
    try {
      const response = await api.get("/api/categories/list", {
        params: { companyId: user?.companyId },
      });
      if (!tags.length) {
        const { data } = await api.get(`/tags/list`);
        setTags(data);
      }
      const { data } = await api.get("contacts", {
        params: { searchParam },
      });
      setOptions(data.contacts);
      setCategories(response.data);
    } catch (err) {
      toastError(err);
    }
  }, [user.companyId, searchParam, tags.length]);


  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);;

  const handleFilter = useCallback(async (page) => {
    setLoading(true);
    const getReportEF = async (params) => {
      const { data } = await api.request({
        url: `/ticket/reports`,
        method: 'GET',
        params
      });
      return data;
    }
    try {
      const data = await getReportEF({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp.map((whats) => whats?.id)),
        users: JSON.stringify(userIds.map((user) => user?.id)),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus.map((status) => status.status)),
        categoryIds: JSON.stringify(categoryIds),
        dateFrom,
        dateTo,
        timeDifference,
        tags: JSON.stringify(selectedTags.map((tag) => Number(tag?.id))),
        page,
        pageSize,
      });

      setTotalTickets(data?.totalTickets?.total || 0);
      setTickets(data?.tickets);
      setUsersSummary(data?.usersSummary);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }, [searchParam, selectedContactId, selectedWhatsapp, userIds, queueIds, selectedStatus, categoryIds, dateFrom, dateTo, timeDifference, selectedTags, pageSize]);

  const handleFilterRef = useRef(handleFilter);

  useEffect(() => {
    handleFilterRef.current = handleFilter;
  }, [handleFilter]);

  useEffect(() => {
    handleFilterRef.current(pageNumber);
  }, [pageNumber]);

  const exportarGridParaExcel = async () => {
    setLoading(true);
    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp.map((whats) => whats.id)),
        users: JSON.stringify(userIds.map((user) => user.id)),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus.map((status) => status.status)),
        categoryIds: JSON.stringify(categoryIds),
        dateFrom,
        dateTo,
        timeDifference,
        tags: JSON.stringify(selectedTags.map((tag) => Number(tag.id))),
        page: 1,
        pageSize: 9999999,
      });
      const ticketsData = data.tickets.map(ticket => ({
        id: ticket?.id,
        Conexão: ticket?.whatsappName,
        Contato: ticket?.contactName || "",
        Usuário: ticket?.userName,
        Número: ticket?.directContactNumber || "",
        Fila: ticket?.queueName,
        Status: ticket?.status,
        ÚltimaMensagem: ticket?.lastMessage,
        DataHoraAbertura: ticket?.createdAt ? new Date(ticket?.createdAt).toLocaleString('pt-BR') : "",
        InicioAtendimento: ticket?.startedAt ? new Date(ticket?.startedAt).toLocaleString('pt-BR') : "",
        DataHoraFechamento: ticket?.closedAt ? new Date(ticket?.closedAt).toLocaleString('pt-BR') : "",
        TempoDeAtendimento: ticket?.supportTime || 0,
        Categória: ticket?.categoryName,
        nps: ticket?.NPS,
        TempoDeEspera: ticket?.timeDifference,
        Tags: ticket?.tags?.filter((ticket) => !!ticket?.name).map((ticket) => ticket?.name).join(' / '),
        InformacaoAdicional: ticket?.contactCustomFields?.filter((custom) => !!custom.name).map((custom) => custom?.name).join(' / '),
        ObservacoesContato: ticket?.ticketNotes?.filter((note) => !!note.note).map((note) => note?.note).join(' / '),
      }));

      const ws = XLSX.utils.json_to_sheet(ticketsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'RelatorioDeAtendimentos');
      XLSX.writeFile(wb, 'relatorio-de-atendimentos.xlsx');
    } catch (error) {

      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
        <UsersSummary open={userSummaryOpen} setOpen={setUserSummaryOpen} users={usersSummary || []}/>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <TimelineTicketDetails
          setIsAscending={setIsAscending}
          isAscending={isAscending}
          modalOpen={modalOpen}
          selectedTicket={selectedTicket}
          handleCloseModal={handleCloseModal}
          handleTicketClick={handleTicketClick}
          ticketId={selectedTicket?.id}
        />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          margin: "0 0 1rem 0",
        }}
      >
        <h3 className={classes.reportsTitle}>{i18n.t("reports.title")}</h3>
        <IconButton onClick={exportarGridParaExcel} className={classes.exportButton}>
          <SaveAlt />
        </IconButton>
      </div>
      <div>

        <FilterComponent
          setUserSummaryOpen={setUserSummaryOpen}
          classes={classes}
          loading={loading}
          setSearchParam={setSearchParam}
          setSelectedContactId={setSelectedContactId}
          setSelectedWhatsapp={setSelectedWhatsapp}
          setSelectedStatus={setSelectedStatus}
          queueIds={queueIds}
          setQueueIds={setQueueIds}
          setUserIds={setUserIds}
          options={options}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          categoryIds={categoryIds}
          setCategoryIds={setCategoryIds}
          timeDifference={timeDifference}
          setTimeDifference={setTimeDifference}
          categories={categories}
          handleFilter={handleFilter}
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />

      </div>
      <Paper className={classes.tableContainer}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="left">Canal</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Usuário</TableCell>
              <TableCell align="left">Número</TableCell>
              <TableCell align="left">Fila</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Abertura</TableCell>
              <TableCell align="center">Início Atend.</TableCell>
              <TableCell align="center">Fechado</TableCell>
              <TableCell align="center">T.Atendido</TableCell>
              <TableCell align="center">NPS</TableCell>
              <TableCell align="center">Tabulação</TableCell>
              <TableCell align="center">T.Espera</TableCell>
              <TableCell align="center">Tags</TableCell>
              <TableCell align="center">Timeline</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton avatar columns={3} />
            ) : (
              <>
                {Array.isArray(tickets) && tickets.length > 0 ? (
                  tickets.map(ticket => (
                    <TableRow key={ticket?.id} className={classes.tableRow}>
                      <TableCell align="center">
                        {ticket.id}
                        <Tooltip title="Acessar Ticket">
                          <Forward
                            onClick={() => { history.push(`/tickets/${ticket.uuid}`) }}
                            fontSize="small"
                            style={{
                              color: "#4caf50",
                              cursor: "pointer",
                              marginLeft: 10,
                              verticalAlign: "middle"
                            }}
                          />
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.whatsappName || "undefined"}>
                          <span>
                            {ticket.whatsappName?.length > 10 ? `${ticket.whatsappName.substring(0, 10)}...` : ticket.whatsappName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.contactName || "undefined"}>
                          <span>
                            {ticket?.contactName?.length > 10 ? `${ticket.contactName.substring(0, 10)}...` : ticket.contactName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.userName || "undefined"}>
                          <span>
                            {ticket?.userName?.length > 10 ? `${ticket.userName.substring(0, 10)}...` : ticket.userName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                          <span>
                            {ticket?.directContactNumber}
                          </span>
                      </TableCell>

                      <TableCell align="left">{ticket.queueName}</TableCell>

                      <TableCell align="center">{ticket.status}</TableCell>

                      <TableCell align="center">
                        {ticket.createdAt ? new Date(ticket.createdAt).toLocaleString('pt-BR') : ""}
                      </TableCell>

                      <TableCell align="center">{ticket.startedAt ? new Date(ticket.startedAt).toLocaleString('pt-BR') : ""}</TableCell>

                      <TableCell align="center">
                        {ticket.closedAt ? new Date(ticket.closedAt).toLocaleString('pt-BR') : ""}
                      </TableCell>

                      <TableCell align="center">{ticket?.supportTime || 0}</TableCell>
                      <TableCell align="center">{ticket.NPS}</TableCell>
                      <TableCell align="center">{ticket.categoryName}</TableCell>
                      <TableCell align="center">{ticket.timeDifference}</TableCell>
                      <Tooltip title={ticket?.tags[0]?.name ? ticket.tags.filter((ticket) => !!ticket.name).map((ticket) => ticket?.name).join(' / ') : "undefined"}>

                        <TableCell align="center">{ticket?.tags[0]?.name ? truncateText(ticket.tags.filter((ticket) => !!ticket.name).map((ticket) => ticket?.name).join(' / '), 8) : ""}</TableCell>
                      </Tooltip>
                      <TableCell onClick={() => handleTicketClick(ticket.id)} align="center"><ConfirmationNumber style={{cursor: "pointer"}} /></TableCell>

                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">Nenhum ticket encontrado</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>


        </Table>
      </Paper>

      {/* Paginação */}
      <div className={classes.pagination}>
        <Pagination
          count={Math.ceil(totalTickets / pageSize)}
          page={pageNumber}
          onChange={(event, value) => setPageNumber(value)}
        />
      </div>
    </div>
  );
};

export default Reports;
