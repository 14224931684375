import React, { useState, useEffect, useReducer, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: "0 !important",
  },

  ticketsList: {
    flex: 1,
    maxHeight: "calc(100vh - 360px)",
    overflowY: "scroll",
    background: theme.palette.primary.neutralContainerBackground,
    ...theme.scrollbarStyles,
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    const updatedTickets = [...state];
    newTickets.forEach((ticket) => {
      const ticketIndex = updatedTickets.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        updatedTickets[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          updatedTickets.unshift(updatedTickets.splice(ticketIndex, 1)[0]);
        }
      } else {
        updatedTickets.push(ticket);
      }
    });

    return updatedTickets;
  }

  if (action.type === "SEARCH_TICKETS") {
    const newTickets = action.payload;
    return newTickets;
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }
  if (action.type === "RELOAD_TICKET") {
    const ticket = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }
    return [...state];
  }
  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsListCustom = (props) => {
  const {
    status,
    searchParam,
    tags,
    tab = "",
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
    setMobileUserOpen,
    setHideMessages,
    contactType,
    selectedStatus = []
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [ticketsRender, setTicketsRender] = useState([]);
  const [visibleTicket, setVisibleTicket] = useState(false);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;

  useEffect(() => {
    const getAll = async (params) => {
      const { data } = await api.request({
          url: '/settings',
          method: 'GET',
          params
      });
      return data;
  }
    getAll()
      .then((response) => {
        const userVisibleTicket = response.some((setting) => {
          return (setting?.key === "userViewTicketsWithoutQueue" &&
            setting?.value === "enabled")
        })
        setVisibleTicket(userVisibleTicket);
      });

  }, [])

  useEffect(() => {
    if(tab === "search") {
      const filteredTickets = ticketsList.filter((ticket) => {
        const userMatch = users?.length === 0 || users?.includes(ticket?.user?.id || ticket.userId);
        const tagMatch =
          tags?.length === 0 || ticket?.tags?.some((tag) => tags.includes(tag.id));
        const ticketStatus = selectedStatus.map((statusOp) => statusOp.status).includes(ticket?.status) || selectedStatus?.length === 0
        return userMatch && ticketStatus && tagMatch;
      });
      
      setTicketsRender(filteredTickets);
    } else {
      setTicketsRender(ticketsList)
    }
  }, [ticketsList, users, tags, selectedStatus]);
  

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, tags, users, selectedQueueIds]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    users: JSON.stringify(users),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues?.map((q) => q.id) || [];
    const filteredTickets = tickets.filter(
      (t) => {
        return (
          queueIds.indexOf(t.queueId) > -1 ||
          (visibleTicket && queueIds.indexOf(t.queueId) === -1 &&
            (t.userId === user.id || t.userId === null || t.userId === undefined))
        )
      }
    );


    if (profile === "user") {
      dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: tickets });
    }
  }, [tickets, status, searchParam, queues, profile, visibleTicket, user]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    const shouldUpdateTicket = (ticket) =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }
      if (data.action === "reload-page") {
        const userQueueIds = queues?.map((q) => q.id) || [];

        if (userQueueIds.includes(data.ticket.queueId)) {
          dispatch({
            type: "RELOAD_TICKET",
            payload: data.ticket,
          });
        } else {
          dispatch({
            type: "DELETE_TICKET",
            payload: data.ticket.id,
          });
          console.log("O usuário não tem permissão para visualizar essa fila:", data.ticket.queueId);
        }
      }


      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      const queueIds = queues?.map((q) => q.id) || [];

      if (
        profile === "user" &&
        (queueIds.indexOf(data.ticket.queue?.id) === -1 ||
          data.ticket.queue === null)
      ) {
        
        return;
      }

      if (data.action === "create" && shouldUpdateTicket(data.ticket)) {

        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: data.ticket,
        });
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues, visibleTicket, searchParam]);

  useEffect(() => {
    const count = ticketsList.filter(ticket => !ticket.isGroup).length;
    if (typeof updateCount === "function") {
      updateCount(count);
    }
  }, [ticketsList, updateCount]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {ticketsRender?.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsRender
                ?.filter((ticket) => {
                  const ticketType = ticket?.whatsapp?.type === null
                    ? "baileys"
                    : ticket?.contact?.instagramId
                      ? "instagram"
                      : ticket?.contact?.messengerId
                        ? "facebook"
                        : "official";

                  if (contactType === null) {
                    return ticket;
                  }
                  return ticketType === contactType;
                })
                .filter((ticket) => tab === "" ? ticket.isGroup.toString() === "false" : true)
                .map((ticket) => (
                  <TicketListItem
                    setHideMessages={setHideMessages}
                    setMobileUserOpen={setMobileUserOpen}
                    ticket={ticket}
                    key={ticket.id}
                  />
                ))}

            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsListCustom;
