import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { Menu, Modal, TextField, Button, useTheme } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";

import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext";
import ForwardModal from "../../components/ForwardMessageModal";

import toastError from "../../errors/toastError";
import { WavoipContext } from "../../context/WavoipContext/WhavoipContext";
import { AuthContext } from "../../context/Auth/AuthContext";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);

	const {
		showSelectMessageCheckbox,
		setShowSelectMessageCheckbox,
		selectedMessages,
		forwardMessageModalOpen,
		setForwardMessageModalOpen
	} = useContext(ForwardMessageContext);
	const { user } = useContext(AuthContext);
	const waData = useContext(WavoipContext);

	const {
		handleWavoipCall,
		setIsActive,
		setIsOpen,
		setPhoneNumber} = waData;

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	// Novo estado para o modal e o corpo da mensagem editada
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [editedMessageBody, setEditedMessageBody] = useState(message.body);

	const handleEditMessage = () => {
		setEditedMessageBody(message.body);
		setEditModalOpen(true);
		handleClose();
	};

	const saveEditedMessage = async () => {
		try {
			await api.post(`/messages/edit/${message.id}`, { body: editedMessageBody });
			setEditModalOpen(false);
		} catch (error) {
			toastError(error);
		}
	};

	const handleSetShowSelectCheckbox = () => {
		setShowSelectMessageCheckbox(!showSelectMessageCheckbox);
		handleClose();
	};

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${message.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleReplyMessage = () => {
		setReplyingMessage(message);
		handleClose();
	};

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
		handleClose();
	};

	const isWithinFifteenMinutes = () => {
		const fifteenMinutesInMilliseconds = 15 * 60 * 1000; // 15 minutos em milissegundos
		const currentTime = new Date();
		const messageTime = new Date(message.updatedAt);

		return currentTime - messageTime <= fifteenMinutesInMilliseconds;
	};

	const handleMakeACall = () => {
		setIsOpen(true);
		setIsActive(true);
		setPhoneNumber(message?.contact.number);
		handleWavoipCall(message?.contact.number, user, null, message?.contact);
		sessionStorage.setItem("ticketContactInfo", JSON.stringify({...message}));
		handleClose();
	}
	
	const theme = useTheme();

	return (
		<>
			<ForwardModal
				modalOpen={forwardMessageModalOpen}
				messages={selectedMessages}
				onClose={() => {
					setForwardMessageModalOpen(false);
					setShowSelectMessageCheckbox(false);
				}}
			/>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={() => setConfirmationOpen(false)}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem onClick={handleSetShowSelectCheckbox}>
					{i18n.t("messageOptionsMenu.forward")}
				</MenuItem>
				{message.fromMe && isWithinFifteenMinutes() && (
					<MenuItem key="edit" onClick={handleEditMessage}>
						{i18n.t("messageOptionsMenu.edit")}
					</MenuItem>
				)}
				{message.fromMe && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("messageOptionsMenu.delete")}
					</MenuItem>
				)}
				<MenuItem onClick={handleReplyMessage}>
					{i18n.t("messageOptionsMenu.reply")}
				</MenuItem>
				<MenuItem onClick={handleMakeACall}>
					Ligar
				</MenuItem>
			</Menu>

			{/* Modal para editar mensagem */}
			<Modal
				open={editModalOpen}
				onClose={() => setEditModalOpen(false)}
				aria-labelledby="edit-message-modal"
				aria-describedby="modal-to-edit-message"
			>
				<div style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					backgroundColor: theme.palette.primary.neutralColor,
					borderRadius: '8px',
				}}>
					<h2 style={{fontSize: "16px", fontFamily: "Poppins", fontWeight: "400", color: theme.palette.primary.neutralTextColor, display: "flex", padding: "1rem", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`}}>{i18n.t("messageOptionsMenu.editMessage")}</h2>
					<TextField
						style={{margin: "1rem", width: "calc(100% - 2rem)"}}
						variant="outlined"
						value={editedMessageBody}
						onChange={(e) => setEditedMessageBody(e.target.value)}
					/>
					<div style={{padding: "1rem", display: "flex", justifyContent: "end", borderTop: `1px solid ${theme.palette.primary.neutralBorder}`}}>
						<Button
							onClick={saveEditedMessage}
							variant="contained"
							color="primary"
						>
							{i18n.t("messageOptionsMenu.save")}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default MessageOptionsMenu;
