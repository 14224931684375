import React, { useState, useEffect, useContext, useRef } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  online: {
    fontSize: 11,
    color: "#25d366"
  },
  offline: {
    fontSize: 11,
    color: "#e1306c"
  },
  modalTitle: {
		fontFamily: "Poppins",
    fontWeight: 400,
		color: theme.palette.primary.neutralTextColor,
    fontSize: "16px"
	},
	btnStyles: {
		border: `none`,
    margin: "6px 16px",
		borderRadius: "4px",
		color: "#FFFFFF",
    fontWeight: "400",
    fontFamily: "Poppins",
    textTransform: "capitalize",
		background: theme.palette.primary.main,
		"&:hover": {
      background: theme.palette.primary.mainReverseByWhite,
		},
	},
}));

const filter = createFilterOptions({
  trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchParam,   setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(initialContact);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const [newContact, setNewContact] = useState({});
  const [whatsapps, setWhatsapps] = useState([]);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { companyId } = user;
  const companyIdRef = useRef(companyId);
  const userQueuesRef = useRef(user.queues);
  const [openAlert, setOpenAlert] = useState(false);
  const [userTicketOpen, setUserTicketOpen] = useState("");
  const [queueTicketOpen, setQueueTicketOpen] = useState("");
  const [selectedWhatsAppType, setSelectedWhatsAppType] = useState("");
  if((openAlert || userTicketOpen || queueTicketOpen) && false) {
    console.log("");
  }

  useEffect(() => {
    if (initialContact?.id !== undefined) {
      setOptions([initialContact]);
      setSelectedContact(initialContact);
    }
  }, [initialContact]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        api
          .get(`/whatsapp`, { params: { companyId: companyIdRef.current, session: 0 } })
          .then(({ data }) => {
            setWhatsapps(data);
          });
      };

      if (userQueuesRef.current.length === 1) {
        setSelectedQueue(userQueuesRef.current[0].id);
      }
      
      fetchContacts();
      setLoading(false);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          const contactType = {
            "waId": "official",
            "whatsappId": null,
            "instagramId": "instagram",
            "messengerId": "facebook"
          }

          data.contacts.forEach(contact => {
            for (const [key, value] of Object.entries(contactType)) {
              if (contact[key] !== null && contact[key] !== undefined) {
                contact.type = value;
                break;
              }
            }
          });

          setOptions(data.contacts.filter((contact) => contact.contactType === (selectedWhatsAppType || "baileys")));
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);


  const handleClose = () => {
    onClose();
    setSearchParam("");
    setOpenAlert(false);
    setUserTicketOpen("");
    setQueueTicketOpen("");
    setSelectedContact(null);
  };

  const handleSaveTicket = async contactId => {
    if (!contactId) return;

    if (selectedQueue === "" && user.profile !== 'admin') {
      toast.error("Selecione uma fila");
      return;
    }

    setLoading(true);

    try {
      const queueId = selectedQueue !== "" ? selectedQueue : null;
      const whatsappId = selectedWhatsapp !== "" ? selectedWhatsapp : null;

      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        queueId,
        whatsappId,
        userId: user.id,
        status: "open",
      });

      onClose(ticket);
    } catch (err) {
      setLoading(false);

      let errorMessage = "O Contato já ta em atendimento nessa fila";
      try {
        const ticket = JSON.parse(err.response.data.error);

        if (ticket.userId !== user?.id) {
          setOpenAlert(true);
          setUserTicketOpen(ticket.user.name);
          setQueueTicketOpen(ticket.queue.name);
        } else {
          setOpenAlert(false);
          setUserTicketOpen("");
          setQueueTicketOpen("");
          onClose(ticket);
        }

        errorMessage = "O Contato já ta em atendimento nessa fila";
      } catch (errorParse) {
        console.error("Erro ao interpretar a resposta do servidor", errorParse);
      }

      toast.error(errorMessage);
    }
  };


  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    } else if (newValue?.name) {
      setNewContact({ name: newValue.name, contactType: selectedWhatsAppType || "baileys" });
      setContactModalOpen(true);
    }
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  const handleAddNewContactTicket = contact => {
    handleSaveTicket(contact.id);
  };

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);
    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }
    return filtered;
  };

  const renderOption = option => {
    if (option.number) {
      return <>
        {/* {IconChannel(option.channel)} */}
        <Typography component="span" style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
          {option.name} - {option.number}
        </Typography>
      </>
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const renderContactAutocomplete = () => {
    if (initialContact === undefined || initialContact.id === undefined) {
      return (
        <Grid xs={12} item>
            <span style={{marginBottom: "0.5rem", display: "flex", fontFamily: "Poppins"}}>Usuário</span>

          <Autocomplete
            fullWidth
            options={options}
            loading={loading}
            disabled={selectedWhatsapp?.length === 0}
            clearOnBlur
            autoHighlight
            freeSolo
            clearOnEscape
            getOptionLabel={renderOptionLabel}
            renderOption={renderOption}
            filterOptions={createAddContactOption}
            onChange={(e, newValue) => handleSelectOption(e, newValue)}
            renderInput={params => (
              <TextField
                {...params}
                label={i18n.t("newTicketModal.fieldLabel")}
                variant="outlined"
                onChange={e => setSearchParam(e.target.value)}
                onKeyPress={e => {
                  if (loading || !selectedContact) return;
                  else if (e.key === "Enter") {
                    handleSaveTicket(selectedContact.id);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )
    }
    return null;
  }
  const theme = useTheme();
  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
        cantChangeContactType={true}
      ></ContactModal>
      <Dialog open={modalOpen} onClose={handleClose}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem", background: theme.palette.primary.neutralBackground}}>
          <h3 className={classes.modalTitle} id="form-dialog-title">
            {i18n.t("newTicketModal.title")}
          </h3>
          <Close style={{cursor:"pointer"}} onClick={handleClose}/>
        </div>
        <DialogContent style={{background: theme.palette.primary.neutralBackground}} dividers>
          <Grid style={{ width: 300 }} container spacing={2}>
          <Grid xs={12} item>
            <div>
              <span style={{marginBottom: "0.5rem", display: "flex", fontFamily: "Poppins"}}>Conexões</span>
              <Select
                required
                fullWidth
                displayEmpty
                variant="outlined"
                value={selectedWhatsapp}
                onChange={(e) => {
                  setOptions([]);
                  setSelectedWhatsAppType(whatsapps.find((connection) => connection.id === e.target.value).type);
                  setSelectedWhatsapp(e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={() => {
                  if (selectedWhatsapp === "") {
                    return "Selecione uma Conexão"
                  }
                  const whatsapp = whatsapps.find(w => w.id === selectedWhatsapp)
                  return whatsapp?.name || ""
                }}
              >
                {whatsapps?.length > 0 && (
                  whatsapps
                    .filter((ticket) => {
                      const isOfficialContact =
                        selectedContact?.contactType === "official" || !selectedContact;
                      const isOfficialTicket = ticket?.type === "official";

                      const contactTypes = [
                        { type: "facebook", typeSelected: (selectedContact?.contactType === "instagram" || selectedContact?.contactType === "facebook") },
                        { type: "baileys", typeSelected: selectedContact?.contactType === "baileys" },
                        { type: "official", typeSelected: selectedContact?.contactType === "official" },

                      ]
                      return ((isOfficialContact && isOfficialTicket) || !selectedContact)
                        ? true
                        : contactTypes.filter(contactType => (contactType?.type === (ticket?.type || "baileys") && contactType?.typeSelected)).length > 0;
                    })
                    .map((whatsapp, key) => (
                      <MenuItem dense key={key} value={whatsapp.id}>
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              style={{
                                fontSize: 14,
                                marginLeft: "10px",
                                display: "inline-flex",
                                alignItems: "center",
                                lineHeight: "2",
                              }}
                            >
                              {whatsapp.name} &nbsp;
                              <p className={whatsapp.status === 'CONNECTED' ? classes.online : classes.offline}>
                                ({whatsapp.status})
                              </p>
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))
                )}

              </Select>
            </div>
            </Grid>
            {/* CONTATO */}

            {renderContactAutocomplete()}
            {/* FILA */}

            <Grid xs={12} item>
            <span style={{marginBottom: "0.5rem", display: "flex", fontFamily: "Poppins"}}>Fila</span>
               <Select
                required
                fullWidth
                displayEmpty
                variant="outlined"
                value={selectedQueue}
                onChange={(e) => {
                  setSelectedQueue(e.target.value)
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={() => {
                  if (selectedQueue === "") {
                    return "Selecione uma fila"
                  }
                  const queue = user.queues.find(q => q.id === selectedQueue)
                  return queue.name
                }}
              >
                {user.queues?.length > 0 &&
                  user.queues.map((queue, key) => (
                    <MenuItem dense key={key} value={queue.id}>
                      <ListItemText primary={queue.name} />
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
            {/* CONEXAO */}
          </Grid>
        </DialogContent>
        <DialogActions style={{background: theme.palette.primary.neutralBackground}}>
          <ButtonWithSpinner
            type="button"
            disabled={loading}
            onClick={() => handleSaveTicket(selectedContact.id)}
            loading={loading}
            className={classes.btnStyles}
          >
            {i18n.t("newTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
        {/* <ShowTicketOpen
          isOpen={openAlert}
          handleClose={handleCloseAlert}
          user={userTicketOpen}
          queue={queueTicketOpen}
			  /> */}
      </Dialog >
    </>
  );
};
export default NewTicketModal;