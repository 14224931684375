import React, { useEffect, useRef } from "react";
import { darken, useTheme } from "@material-ui/core/styles";
import { Paper, Tab, TextField, InputAdornment, Button } from "@material-ui/core";
import { FaPlus } from "react-icons/fa";
import SearchIcon from "@material-ui/icons/Search";
import { i18n } from "../../translate/i18n";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Close, SearchRounded } from "@material-ui/icons";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { ModalUsersFilter } from "../ModalUsersFilter";
import { StatusFilterSearch } from "../StatusFilterSearch";
import { ModalTagsFilter } from "../ModalTagsFilter";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    tagBox: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        marginTop: 8,
        ...theme.scrollbarStyles
    },
    filterModal: {
        position: "absolute",
        top: "48px",
        left: "0px",
        zIndex: "1000",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        borderRadius: "8px",
        backgroundColor: theme.mode === "light" ? "#FFFFFF" : theme.palette.primary.neutralBackground,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        [theme.breakpoints.down("sm")]: {
            width: "300px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

        }
    }
}));

const TicketTabsHeader = ({
    setTab, tab,
    classes, handleSearch,
    filterModalOpen, setFilterModal,
    setNewTicketModalOpen, showGroupTab,
    searchInputRef, searchParam,
    setSelectedQueueIds,
    selectedQueueIds, user, profile,
    setSelectedUsers,
    selectedUsers, handleSelectedUsers, selecteds,
    setSelecteds, selectedStatus, handleSelectedStatus,
    handleSelectedTags, setSelectedStatus, setFoundTags, setSelectedTags,
    foundTags, selectedTags
}) => {
    const theme = useTheme();
    const modalRef = useRef(null);
    const ticketsClasses = useStyles();
    const anyFilter = (selectedUsers?.length + selecteds?.length + selectedStatus?.length + selectedTags?.length) > 0 || (user?.queues?.length > selectedQueueIds?.length);
    return (
        <>
            <div style={{ display: "flex", gap: "0.3rem", background: theme.palette.primary.neutralBackground }}>
                <Tab
                    value={"open"}
                    label={"Diretas"}
                    classes={{ root: tab === "open" ? classes.groupTabActive : classes.groupTab }}
                    onClick={() => setTab("open")}
                    style={{ color: theme.palette.primary.mainReverseByWhite }}
                />
                {showGroupTab && (
                    <Tab
                        value={"group"}
                        label={i18n.t("tickets.tabs.group.title")}
                        classes={{ root: tab === "group" ? classes.groupTabActive : classes.groupTab }}
                        onClick={() => setTab("group")}
                    />
                )}
            </div>
            <Paper elevation={0} square className={classes.tabsHeader}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "1rem 1rem 0 1rem",
                    }}
                >
                    <TextField
                        size="small"
                        type="text"
                        inputRef={searchInputRef}
                        value={searchParam}
                        onChange={handleSearch}
                        placeholder="Buscar"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment style={{ paddingLeft: "0", width: "20px" }}>
                                    <SearchRounded style={{ color: "#999191", width: "20px", fontFamily: "Poppins", fontWeight: "400" }} />
                                </InputAdornment>
                            ),
                            style: { paddingLeft: ".4rem", borderRadius: "8px" },
                        }}
                        style={{
                            flex: 1,
                            maxWidth: "300px",
                            color: "#534D4D",
                            background: theme.palette.primary.inputBackground,
                            borderRadius: "8px",
                            marginRight: "1rem",
                            paddingLeft: "0",
                        }}
                    />
                    <div style={{ display: "flex", gap: "0.3rem", position: "relative" }}>
                        {/* Modal */}
                        {filterModalOpen && (
                            <div
                                ref={modalRef}
                                className={ticketsClasses.filterModal}
                            >   <div style={{ display: "flex", justifyContent: "space-between", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`, padding: "1rem" }}>
                                    <h2 style={{ color: theme.palette.primary.neutralTextColor, fontFamily: "Poppins", fontWeight: 400, fontSize: "16px" }}>
                                        Filtros
                                    </h2>
                                    <Close onClick={() => setFilterModal(false)} style={{ cursor: "pointer", color: theme.palette.primary.neutralTextColor }} />
                                </div>
                                <div className={classes.maxFilter} style={{ padding: "0 0.8rem 0 1rem" }}>
                                    {/* Conteúdo do filtro */}
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "1rem", cursor: "pointer" }}>
                                            <label style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: "#9F9797" }}>
                                                Filas
                                            </label>
                                        </div>
                                        <TicketsQueueSelect
                                            style={{ width: "100%" }}
                                            selectedQueueIds={selectedQueueIds}
                                            userQueues={user?.queues}
                                            onChange={(values) => setSelectedQueueIds(values)}
                                        />
                                    </div>

                                    {profile === "admin" && (
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "1rem" }}>
                                                <label style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: "#9F9797" }}>
                                                    Usuários
                                                </label>
                                                <button
                                                    onClick={() => {
                                                        setSelecteds([]);
                                                        setSelectedUsers([]);
                                                    }}
                                                    style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: theme.palette.primary.main, background: "none", border: "none", cursor: "pointer" }}
                                                >
                                                    Limpar
                                                </button>
                                            </div>
                                            <ModalUsersFilter selectedUsers={selectedUsers} onFiltered={handleSelectedUsers} selecteds={selecteds} setSelecteds={setSelecteds} />
                                        </div>
                                    )}

                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "1rem" }}>
                                            <label style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: "#9F9797" }}>
                                                Status
                                            </label>
                                            <button
                                                onClick={() => setSelectedStatus([])}
                                                style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: theme.palette.primary.main, background: "none", border: "none", cursor: "pointer" }}
                                            >
                                                Limpar
                                            </button>
                                        </div>
                                        <StatusFilterSearch setSelecteds={setSelectedStatus} selecteds={selectedStatus} onFiltered={handleSelectedStatus} />
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "1rem" }}>
                                            <label style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: "#9F9797" }}>
                                                Tags
                                            </label>
                                            <button
                                                onClick={() => {
                                                    setFoundTags([]);
                                                    setSelectedTags([]);
                                                }}
                                                style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", color: theme.palette.primary.main, background: "none", border: "none", cursor: "pointer" }}
                                            >
                                                Limpar
                                            </button>
                                        </div>
                                        <ModalTagsFilter foundTags={foundTags} setFoundTags={setFoundTags} widthStyle={"100%"} selectedTags={selectedTags} onFiltered={handleSelectedTags} />
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", padding: "1rem", borderTop: `1px solid ${theme.palette.primary.neutralBorder}` }}>
                                    <Button
                                        onClick={() => {
                                            setSelecteds([]);
                                            setSelectedUsers([]);
                                            setSelectedStatus([]);
                                            setFoundTags([]);
                                            setSelectedTags([]);
                                        }}
                                        style={{ fontFamily: "Poppins", background: theme.palette.primary.main, color: theme.mode === "light" ? "#9F9797" : "#FFFFFF", fontSize: "14px", fontWeight: 400, border: `1px solid ${theme.palette.primary.neutralBorder}`, borderRadius: "4px", textTransform: "capitalize", background: "transparent" }}
                                    >
                                        Limpar filtros
                                    </Button>
                                    <Button
                                        onClick={() => setFilterModal(false)}
                                        style={{ fontFamily: "Poppins", background: theme.palette.primary.main, color: "#FFFFFF", fontSize: "14px", fontWeight: 400, borderRadius: "4px", textTransform: "capitalize" }}
                                    >
                                        Manter filtro
                                    </Button>
                                </div>
                            </div>
                        )}

                        {tab !== "closed" && (
                            <Button
                                variant="outlined"
                                color="primary"
                                title="Filtros"
                                onClick={() => setFilterModal(true)}
                                style={{
                                    minWidth: "40px",
                                    width: "40px",
                                    height: "40px",
                                    fontSize: "20px",
                                    padding: "0",
                                    border: "none",
                                    borderRadius: "8px",
                                    color: anyFilter ? "#FFFFFF" : theme.palette.primary.neutralIcon,
                                    background: anyFilter ? `${darken(theme.palette.primary.main, 0.2)}` : theme.palette.primary.neutralIconBackground,
                                    fontWeight: "bold",
                                }}
                            >
                                <FilterListIcon />
                            </Button>
                        )}

                        <Button
                            color="primary"
                            title="Novo Ticket"
                            onClick={() => setNewTicketModalOpen(true)}
                            style={{
                                minWidth: "40px",
                                width: "40px",
                                height: "40px",
                                fontSize: "20px",
                                padding: "0",
                                border: "none",
                                borderRadius: "8px",
                                color: theme.palette.primary.main,
                                background: `${theme.palette.primary.main}30`,
                                fontWeight: "bold",
                            }}
                        >
                            <FaPlus />
                        </Button>
                    </div>
                </div>
            </Paper>
        </>
    );
};

export default TicketTabsHeader;
