import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import truncateText from '../../utils/truncateText';
import { Tooltip } from '@material-ui/core';
import ToolTip from '../ToolTips';
import { DeleteOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    inline: {
        width: '100%',
        fontFamily: "Poppins",
        fontSize: "12px",
        marginBottom: "1rem"
    },
    icon: {
        color: "#DFD8D8",
    },
    nameDateWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: "1rem"
    },
    name: {
        fontSize: "12px"
    },
    date: {
        textAlign: 'right',
        fontSize: "12px"

    },
}));

export default function ContactNotesDialogListItem(props) {
    const { note, deleteItem } = props;
    const classes = useStyles();

    const handleDelete = (item) => {
        deleteItem(item);
    };

    return (
        <ListItem style={{ paddingLeft: '0', border: "1px solid #DFD8D8", borderRadius: "4px", padding: "0 0.5rem", fontSize: "12px", position: "relative", overflow: "hidden" }}>
            <ListItemText
                primary={
                    <>
                        <Tooltip title={note.note}>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {note.note}
                            </Typography>
                        </Tooltip>
                    </>
                }
                secondary={
                    <div className={classes.nameDateWrapper}>
                        <Typography className={classes.date}>
                            {moment(note.createdAt).format('DD/MM/YY HH:mm')}
                        </Typography>
                        <Tooltip title={note.user.name}>
                            <Typography className={classes.name}>
                                {truncateText(note.user.name, 8)}
                            </Typography>
                        </Tooltip>
                    </div>
                }
            />
            <div style={{ position: "absolute", top: "0.5rem", right: "0.3rem", cursor: "pointer"}}>
                <DeleteOutline onClick={() => handleDelete(note)} className={classes.icon} />
            </div>
        </ListItem>
    );
}

ContactNotesDialogListItem.propTypes = {
    note: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired,
};
