import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { has, isArray } from "lodash";

import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import moment from "moment";
import transformToEnvVars from "../../utils/envToVars";
import ColorModeContext from "../../layout/themeContext";
import themeDefault from "../../static/themeDefault";
import { randomBytes, createCipheriv } from "crypto";

const secretKey = process.env.REACT_APP_SECRET_KEY || "";
const key = Buffer.from(secretKey.padEnd(32, ' ').slice(0, 32), 'utf8');

const useAuth = () => {
  const history = useHistory();
  const { colorMode } = useContext(ColorModeContext);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await api.post("/auth/refresh_token");
        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("companyId");
        colorMode.setPageTheme(themeDefault);
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    (async () => {
      if (token) {
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
        } catch (err) {
          toastError(err);
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {

      const socket = socketConnection({ companyId });

      socket.on(`company-${companyId}-user`, (data) => {
        if (data.action === "update" && data.user.id === user.id) {
          setUser(data.user);
        }
      });


      return () => {
        socket.disconnect();
      };
    }

  }, [user]);

  function encryptPassword(password) {
    const iv = randomBytes(16);

    const cipher = createCipheriv("aes-256-ctr", key, iv);
    let encrypted = cipher.update(password, "utf8", "hex");
    encrypted += cipher.final("hex");
  
    return `${iv.toString("hex")}:${encrypted}`;
  }
  
  const handleLogin = async (userData, isMobile = false) => {
    setLoading(true);
    const encryptedPassword = encryptPassword(userData.password);
    try {
      const payload = { ...userData, password: encryptedPassword };

      const { data } = await api.post("/auth/login", payload);

      const token = data.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const {
        user: { companyId, id, company },
      } = data;

      const themeData = null;
      if (!themeData) {
        localStorage.setItem("pageTheme", JSON.stringify(themeDefault))
        colorMode.setPageTheme(themeDefault);
      } else {
        localStorage.setItem("pageTheme", JSON.stringify(transformToEnvVars(themeData)))
        colorMode.setPageTheme(transformToEnvVars(themeData));
      }

      if (has(company, "settings") && isArray(company.settings)) {

        const campaignSetting = company.settings.find(
          (s) => s.key === "campaignsEnabled"
        );
        if (campaignSetting && campaignSetting.value === "true") {
          localStorage.setItem("cshow", null);
        }

        const callSetting = company.settings.find(
          (s) => s.key === "callsEnabled"
        );

        if (callSetting && callSetting.value === "true") {
          localStorage.setItem("callEnabled", "true");
        } else {
          localStorage.setItem("callEnabled", "false");
        }

        const ticketButtonsToTopSetting = company.settings.find(
          (s) => s.key === "ticketButtonsToTop"
        );

        if (ticketButtonsToTopSetting && ticketButtonsToTopSetting.value === "true") {
          localStorage.setItem("ticketButtonsToTop", "true");
        } else {
          localStorage.setItem("ticketButtonsToTop", "false");
        }

        const isPhoneOnMenu = company.settings.find(
          (s) => s.key === "isPhoneOnMenu"
        );

        if (isPhoneOnMenu && isPhoneOnMenu.value === "true") {
          localStorage.setItem("isPhoneOnMenu", "true");
        } else {
          localStorage.setItem("isPhoneOnMenu", "false");
        }
      }

      moment.locale("pt-br");
      const dueDate = data.user.company.dueDate;
      const vencimento = moment(dueDate).format("DD/MM/yyyy");

      var diff = moment(dueDate).diff(moment(moment()).format());

      var before = moment(moment().format()).isBefore(dueDate);
      var dias = moment.duration(diff).asDays();

      if (before === true) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("userId", id);
        localStorage.setItem("companyDueDate", vencimento);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        setUser(data.user);
        setIsAuth(true);
        toast.success(i18n.t("auth.toasts.success"));
        if (Math.round(dias) < 5) {
          toast.warn(
            `Sua assinatura vence em ${Math.round(dias)} ${Math.round(dias) === 1 ? "dia" : "dias"
            } `
          );
        }

        if (data.user.profile === "user" || isMobile) {
          history.push("/tickets");
          setLoading(false);
          return
        }

        history.push("/");
        setLoading(false);
      } else {
        toastError(
          `Opss! Sua assinatura venceu ${vencimento}. Entre em contato com o Suporte para mais informações!`
        );
        setLoading(false);
      }
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };


  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");
      setIsAuth(false);
      setUser({});
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");
      localStorage.removeItem("userId");
      localStorage.removeItem("cshow");
      api.defaults.headers.Authorization = undefined;
      setLoading(false);
      history.push("/login");
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const getCurrentUserInfo = async () => {
    try {
      const { data } = await api.get("/auth/me");
      return data;
    } catch (err) {
      toastError(err);
    }
  };

  return {
    isAuth,
    user,
    loading,
    handleLogin,
    handleLogout,
    getCurrentUserInfo,
  };
};

export default useAuth;