import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { green, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import { Modal, Button, Select, MenuItem, useMediaQuery } from '@material-ui/core';

import api from "../../services/api";
import { Chip, IconButton, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";
import FaceIcon from "@material-ui/icons/Face";
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReplayIcon from '@material-ui/icons/Replay';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import baileyIcon from "../../assets/baileys-icon.png"
import { VisibilityOutlined } from "@material-ui/icons";
import truncateText from "../../utils/truncateText";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    padding: "1rem 1rem 0.8rem 1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    margin: "0.5rem 0 0 0",
    borderTop: `1px solid ${theme.palette.primary.neutralBorder}`,
    borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
  },

  pendingTicket: {
    cursor: "unset",
  },
  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    background: theme.palette.primary.main,
    top: "2px",
    right: "20px",
    borderRadius: 0,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  connectionTag: {
    background: "#00944030",
    color: "#009440",
    fontWeight: '400',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "10px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none"
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    marginLeft: "5px",
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontWeight: 600
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -0
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "0%",
    marginLeft: "5px",
  },


  badgeStyle: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontSize: "10px"
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },

  ticketQueueColor: {
    flex: "none",
    width: "3px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13
  },
  secondaryContentSecond: {
    display: 'flex',
    gap: "0.3rem"
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },

  },
  darkerColor: {
    color: theme.palette.tertiary,
  },
  darkBorder: {
    border: `1px solid ${theme.palette.primary.neutralBorder} !important`
  },
  darkBorderOpacity: {
    border: `2px solid ${theme.palette.primary.neutralBorder} !important`
  },
}));

const TicketListItemCustom = ({ ticket, setMobileUserOpen, setHideMessages, hideCategory = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [ticketToClose, setTicketToClose] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const [channelType, setChannelType] = useState(null);

  if (loading && false) {
    console.log("INFO", { ticketQueueName, ticketQueueColor, tag, whatsAppName })
  }

  useEffect(() => {
    if (!ticket.id) return;
    const fetchChannelType = async () => {
      try {
        const { data } = await api.get(`/tickets/${ticket.id}`);
        setChannelType(data.whatsapp?.type || null);
      } catch (err) {
        toastError(err);
      }
    };

    fetchChannelType();

  }, [ticket.id]);


  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name?.toUpperCase());
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    setTag(ticket?.tags);

    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        if (!companyId) {
          return
          throw new Error("CompanyId não encontrado.");
        }
        if (hideCategory) {
          return
        }
        const { data } = await api.get('/api/categories/list', {
          params: { companyId }
        });

        setCategories(data);
      } catch (error) {
        console.error("Erro ao carregar as categorias:", error.response || error.message);
        toastError("Erro ao carregar as categorias.");
      }
    };
    fetchCategories();
  }, []);


  const handleCloseTicketSemModal = async (id) => {
    setTag(ticket?.tags);
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
  };

  const handleOpenCloseModal = (ticketId) => {
    setTicketToClose(ticketId);
    setOpenModal(true);
  };

  const handleCloseTicket = async () => {
    if (!selectedCategory || !selectedSubcategory) {
      toastError('Por favor, selecione uma categoria e uma subcategoria antes de fechar o ticket.');
      return;
    }

    setLoading(true);
    try {
      await api.put(`/tickets/${ticketToClose}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        categoryId: selectedSubcategory,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    setOpenModal(false);
    history.push(`/tickets/`);
  };



  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const maxOpenTickets = (err) => {

    if (err.response && err.response.data) {
      const errorMessage = err.response.data.error;
      const maxOpenTickets = err.response.data.maxOpenTickets;
      if (maxOpenTickets !== undefined) {
        toastError(`${errorMessage}: ${maxOpenTickets}`);
      } else {
        toastError(errorMessage);
      }
    } else {
      console.log(err);
    }
    setLoading(false);
  };


  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      let settingIndex;

      try {
        const { data } = await api.get("/settings/");

        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");

      } catch (err) {
        console.log('Error 2: ', err);


      }

      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);

      }

    } catch (err) {
      maxOpenTickets(err);
      setLoading(false);

    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {

    const msg = `Olá, meu nome é ${user?.name} e agora vou prosseguir com seu atendimento! Em que posso ajudar?`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `*Mensagem Automática:*\n${msg.trim()}`,
    };
    try {
      if (channelType === "official") {
        // Se o canal for oficial, usar a rota de mensagem oficial
        await api.post(`/official-message/${id}`, message);
      } else if (channelType !== null) {
        // Se for um canal de hub
        await api.post(`/hub-message/${id}`, message);
      } else {
        // Se for um canal padrão
        await api.post(`/messages/${id}`, message);
      }
    } catch (err) {
      toastError(err);

    }
  };

  const handleSelectTicket = (ticket, event) => {
    event.stopPropagation();
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
    if (typeof setMobileUserOpen === 'function') {
      setMobileUserOpen(true);
    }
  };

  const getTruncatedName = (name) => {
    const maxLength = 10;
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  const renderTicketInfo = () => {
    if (ticketUser) {

      return (
        <>
          {/* {ticket.chatbot && (
            <Tooltip title="Chatbotdsa">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )} */}

          {/* </span> */}
        </>
      );
    } else {
      return (
        <>
          {/* {ticket.chatbot && (
            <Tooltip title="Chatbotfds3e">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )} */}
        </>
      );
    }
  };

  const handleOpenTransferModal = () => {
    setTransferTicketModalOpen(true);
  }

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const isMobile = useMediaQuery("(max-width: 900px)");
  const ticketButtonsToTop = localStorage.getItem("ticketButtonsToTop") === "true";
  return (
    <React.Fragment key={ticket.id}>

      <TransferTicketModalCustom
        ticket={ticket}
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
      />

      {/* Modal de espiar conversa */}
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={(e) => {
          e.stopPropagation();
          setOpenTicketMessageDialog(false)
        }}
        ticketId={ticket.id}
        markAsRead={false}
      />

      <ListItem dense button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          e.stopPropagation();
          handleSelectTicket(ticket, e);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip arrow placement="right" title={ticket.queue?.name?.toUpperCase() || "SEM FILA"} >
          <span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
        </Tooltip>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
            <Tooltip title={ticket?.contact?.name}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  color: theme.palette.primary.neutralTextColor,
                  fontFamily: "Inter",
                  fontWeight: 500
                }}
              >
                {truncateText(ticket?.contact?.name || "")}

              </Typography>
            </Tooltip>
            <Avatar
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                background: "transparent",
              }}
            >
              {ticket?.contact?.number && (!ticket?.whatsapp || !ticket?.whatsapp?.type) && (
                <img style={{ width: "25px" }} src={baileyIcon} alt="WhatsApp Bailey" />
              )}
              {ticket?.contact?.number && ticket?.whatsapp?.type === "official" && (
                <WhatsAppIcon style={{ color: "white", fontSize: "16px" }} />
              )}
              {ticket?.contact?.messengerId && (
                <FacebookIcon style={{ color: "white", fontSize: "16px" }} />
              )}
              {ticket?.contact?.instagramId && (
                <InstagramIcon style={{ color: "white", fontSize: "16px" }} />
              )}
            </Avatar>
          </div>
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
            style={{
              color: theme.palette.primary.neutralTextColor,
              fontWeight: 400,
              fontSize: "12px",
              fontFamily: "Inter",
            }}
          >

            {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
              <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
            ) : (
              <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
            )}
          </Typography>
        </div>

        <div style={{ display: "flex" }}>
          <ListItemAvatar style={{ position: "relative", width: "65px", height: "65px" }}>
            <Avatar
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
              src={ticket?.contact?.profilePicUrl || ""}
            />

          </ListItemAvatar>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <span className={classes.contactNameWrapper}>

              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >

                <span style={{ marginTop: 4, }} className={classes.secondaryContentSecond} >

                  {ticketUser ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title={ticketUser} cursor>
                        <Chip
                          size="small"
                          style={{
                            borderRadius: "4px",
                            fontSize: "10px",
                            display: "flex",
                            position: "relative",
                            top: "1px",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                            color: theme.palette.primary.main,
                            background: `${theme.palette.primary.main}30`,
                            border: "none",
                            textTransform: "capitalize" // O CSS aplica a capitalização
                          }}
                          label={getTruncatedName(ticketUser).toLowerCase()} // Certifique-se de que a string esteja em lowercase para o CSS funcionar
                        />
                      </Tooltip>
                    </div>

                  ) : <br></br>}

                  {ticket?.whatsapp?.name ? (
                    <Tooltip title={ticket?.whatsapp?.name}>
                      <Badge className={classes.connectionTag}>{truncateText(ticket?.whatsapp?.name || "", 10)}</Badge>
                    </Tooltip>
                  ) : <br></br>}
                  <Tooltip title={ticket.queue?.name}>
                    <Badge style={{ backgroundColor: `${ticket.queue?.color}30` || "#7c7c7c30", color: ticket.queue?.color }} className={classes.connectionTag}>{truncateText(ticket?.queue?.name || "", 10) || "Sem fila"}</Badge>
                  </Tooltip>
                </span>
              </Typography>
              <Badge

                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>

            <span className={classes.secondaryContentSecond} >

              <Box sx={{ display: 'flex', gap: ".3rem", marginLeft: "10px" }}>
                {(ticket.status !== "closed" && !ticketButtonsToTop && ticket.isGroup === false) && (
                  <Tooltip arrow title="Finalizar ticket">
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleCloseTicketSemModal(ticket.id);
                      }}
                      style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      color="primary"
                      className={classes.darkBorderOpacity}
                      size="small"
                    >
                      <HighlightOffIcon style={{ fontSize: "20px", color: "red" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Espiar Conversa">
                  <IconButton
                    style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", alignItems: "center" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenTicketMessageDialog(true)
                    }}
                    className={classes.darkBorderOpacity}
                    size="small"
                  >
                    <VisibilityOutlined

                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        verticalAlign: "middle"
                      }}
                    />
                  </IconButton>

                </Tooltip>
                {ticket.status === "open" && <Tooltip arrow title="Transferir ticket">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenTransferModal();
                    }}
                    style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    size="small"
                    className={classes.darkBorderOpacity}
                  >
                    <SyncAltIcon style={{ fontSize: "18px" }} />
                  </IconButton>
                </Tooltip>}
                {ticket.status === "pending" && (
                  <>

                    <Tooltip arrow title="Aceitar ticket">
                      <IconButton
                        style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        onClick={e => {
                          e.stopPropagation(); // Impede a propagação do evento
                          handleAcepptTicket(ticket.id)
                        }}
                        className={classes.darkBorderOpacity}
                        size="small"
                      >
                        <CheckIcon style={{ fontSize: "18px" }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                {(ticket.status === "open" && !ticketButtonsToTop) && (
                  <>
                    <Tooltip arrow title="Resolver ticket">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          return ticket.isGroup ? handleCloseTicketSemModal(ticket.id) : handleOpenCloseModal(ticket.id)
                        }
                        }
                        style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        color="primary"
                        className={classes.darkBorderOpacity}
                        size="small"
                      >
                        <CheckIcon style={{ fontSize: "18px", color: "green" }} />
                      </IconButton>
                    </Tooltip>


                  </>
                )}

                {ticket.status === "closed" && (
                  <Tooltip arrow title="Reabrir ticket">
                    <IconButton
                      style={{ borderRadius: "4px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      onClick={e => handleReopenTicket(ticket.id)}
                      className={classes.darkBorderOpacity}
                      size="small"
                    >
                      <ReplayIcon style={{ fontSize: "18px" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </span>
          </div>
        </div>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>


              <ListItemSecondaryAction>
                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
              </ListItemSecondaryAction>
            </span>
          }
        />
      </ListItem>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '300px' : '300px',
          backgroundColor: theme.palette.primary.neutralColor,
          padding: '0',
          borderRadius: '4px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        }}>
          <div>
            <h2 style={{
              marginBottom: '20px',
              color: '#333',
              width: "100%",
              fontSize: '16px',
              fontWeight: '400',
              padding: "16px",
              borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`
            }}>
              Categoria e Subcategoria
            </h2>
          </div>

          {/* Seleção de Categoria Principal */}
          <div style={{ marginBottom: '20px', padding: "0 1rem" }}>
            <label style={{ fontSize: '12px', color: '#555', fontFamily: "Poppins" }}>Categoria Principal</label>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              fullWidth
              variant="outlined"
              style={{
                marginTop: '10px',
                borderRadius: '8px',
                backgroundColor: '#fff',
              }}
            >
              {categories.filter(category => category.parent_id === null).map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* Seleção de Subcategoria (Baseada na Categoria Principal Selecionada) */}
          <div style={{ marginBottom: '20px', padding: "0 1rem" }}>
            <label style={{ fontSize: '12px', color: '#555', fontFamily: "Poppins" }}>Subcategoria</label>
            <Select
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={!selectedCategory} // Desabilitar se nenhuma categoria principal for selecionada
              style={{
                marginTop: '10px',
                borderRadius: '8px',
                backgroundColor: selectedCategory ? '#fff' : '#f0f0f0',
              }}
            >
              {categories
                .find(category => category.id === selectedCategory)?.subcategories
                ?.map(subcategory => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div style={{ padding: "1rem", display: 'flex', justifyContent: 'flex-end', borderTop: `1px solid ${theme.palette.primary.neutralBorder}` }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseTicket}
              style={{
                borderRadius: '4px',
                padding: '5px 10px',
                fontSize: '14px',
                color: "white",
                background: theme.palette.primary.main,
                textTransform: "capitalize"
              }}
            >
              Finalizar Ticket
            </Button>
          </div>
        </div>
      </Modal>

    </React.Fragment>
  );
};

export default TicketListItemCustom;