import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import withWidth from "@material-ui/core/withWidth";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import { isNil } from "lodash";
import ListAltIcon from "@material-ui/icons/ListAlt";
import "./style.css";
import { i18n } from "../../translate/i18n";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, grey } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CreateIcon from "@material-ui/icons/Create";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, Tooltip, useMediaQuery } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";
import QuickMessageSelectionDialog from "../../components/QuickMessageSelectionDialog";

import api from "../../services/api";
import axios from "axios";

import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

import useQuickMessages from "../../hooks/useQuickMessages";
import { ArrowUpwardOutlined, MicOutlined, ReplyOutlined, SendOutlined } from "@material-ui/icons";
import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: theme.palette.bordabox,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "0 0 8px 8px"
  },

  newMessageBox: {
    backgroundColor: theme.palette.primary.neutralColor,
    width: "100%",
    display: "flex",
    padding: "7px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0 0 8px 8px"
  },

  messageInputWrapper: {
    backgroundColor: theme.palette.inputdigita,
    display: "flex",
    borderRadius: 4,
    minHeight: "64px",
    padding: "0.3rem 0",
    fontSize: "12px",
    marginBottom: "0.5rem",
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    fontFamily: "Poppins",
    ...theme.scrollbarStyles,
    "& textarea": {
      fontFamily: "Poppins",
    }
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  ForwardMessageIcons: {
    color: grey[700],
    transform: 'scaleX(-1)'
  },

  uploadInput: {
    display: "none",
  },

  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontFamily: 'Roboto',
    borderRadius: '4px',
    padding: '3px 12px',
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: '#e06700',
    },
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  iconButton: {
    borderRadius: '4px',
    transition: "background-color 0.3s, color 0.3s",

  },
  selected: {
    color: theme.palette.primary.main,  
  },

}));

const EmojiOptions = React.forwardRef((props, ref) => {
  const { disabled, showEmoji, setShowEmoji, handleAddEmoji } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <IconButton
        aria-label="emojiPicker"
        component="span"
        disabled={disabled}
        onClick={(e) => setShowEmoji((prevState) => !prevState)}
      >
        <MoodIcon className={classes.sendMessageIcons} />
      </IconButton>
      {showEmoji && (
        <Picker
          perLine={16}
          showPreview={false}
          style={{ position: 'absolute', bottom: '48px' }}
          showSkinTones={false}
          onSelect={handleAddEmoji}
        />
      )}
    </div>
  );
});

const SignSwitch = ({ setSignMessage, signMessage, disabled }) => {
  const classes = useStyles();
  const alwaysSignActive = localStorage.getItem("alwaysSignActive") === "true";

  return (
    <Tooltip title={i18n.t("messagesInput.signMessage")}>
      <span>
        <IconButton
          disabled={disabled}
          onClick={() => setSignMessage(alwaysSignActive ? true : !signMessage)}
          className={`${classes.iconButton} ${signMessage ? classes.selected : ""}`}
          size="small"
        >
          <CreateIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};


const FileInput = (props) => {
  const { handleChangeMedias, disableOption } = props;
  const classes = useStyles();
  return (
    <>
      <input
        multiple
        type="file"
        id="upload-button"
        disabled={disableOption()}
        className={classes.uploadInput}
        onChange={handleChangeMedias}
      />
      <label htmlFor="upload-button">
        <IconButton
          aria-label="upload"
          component="span"
          disabled={disableOption()}
        >
          <AttachFileIcon className={classes.sendMessageIcons} />
        </IconButton>
      </label>
    </>
  );
};

const ActionButtons = (props) => {
  const theme = useTheme();
  const {
    inputMessage,
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording,
    handleOpenModalForward,
    showSelectMessageCheckbox,
    scrollToTop
  } = props;
  const classes = useStyles();
  if (inputMessage || showSelectMessageCheckbox) {
    return (
      <IconButton
        aria-label="sendMessage"
        component="span"
        onClick={showSelectMessageCheckbox ? handleOpenModalForward : handleSendMessage}
        disabled={loading}
      >
        {showSelectMessageCheckbox ?
          <ReplyOutlined className={classes.ForwardMessageIcons} /> : <SendOutlined />}      </IconButton>
    );
  } else if (recording) {
    return (
      <div className={classes.recorderWrapper}>
        <IconButton
          aria-label="cancelRecording"
          component="span"
          fontSize="large"
          disabled={loading}
          onClick={handleCancelAudio}
        >
          <HighlightOffIcon className={classes.cancelAudioIcon} />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.audioLoading} />
          </div>
        ) : (
          <RecordingTimer />
        )}

        <IconButton
          aria-label="sendRecordedAudio"
          component="span"
          onClick={handleUploadAudio}
          disabled={loading}
        >
          <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
        </IconButton>
      </div>
    );
  } else {
    return (
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={handleStartRecording}
      >
        <MicOutlined />
      </IconButton>
    );
  }
};

const CustomInput = (props) => {
  const {
    loading,
    inputRef,
    ticketStatus,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    handleInputPaste,
    disableOption,
    handleQuickAnswersClick,
    editingMessage,
    replyingMessage,
    isGroup,
    ticket,
    setGroupUsers,
    setIsTaggingGroupUser,
    scrollToTop
  } = props;
  const classes = useStyles();
  const [quickMessages, setQuickMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const { user } = useContext(AuthContext);
  const { list: listQuickMessages } = useQuickMessages();

  const stableSetInputMessage = useCallback((message) => {
    setInputMessage(message);
  }, [setInputMessage]);

  useEffect(() => {
    if (editingMessage) {
      stableSetInputMessage(editingMessage.body);
    }
  }, [editingMessage, replyingMessage, stableSetInputMessage]);

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map((m) => {
        let truncatedMessage = m.message;
        if (isString(truncatedMessage) && truncatedMessage.length > 35) {
          truncatedMessage = m.message.substring(0, 35) + "...";
        }
        return {
          value: m.message,
          label: `/${m.shortcode} - ${truncatedMessage}`,
          mediaPath: m.mediaPath,
        };
      });
      setQuickMessages(options);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 1
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const filteredOptions = quickMessages.filter(
        (m) => m.label.indexOf(inputMessage) > -1
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
  }, [inputMessage]);

  useEffect(() => {
    if (inputMessage.includes("@")) {
      const caretPosition = inputRef.current.selectionStart;

      const lastAtIndex = inputMessage.lastIndexOf('@', caretPosition); // Limita a busca até a posição do caret

      if (caretPosition === lastAtIndex + 1 || inputMessage[caretPosition - 1] !== ' ') {
        const usernameAfterLastAt = inputMessage.slice(lastAtIndex + 1, caretPosition).trim();

        if (usernameAfterLastAt) {
          const timer = setTimeout(async () => {
            if (isGroup) {
              const { data } = await api.get(`/quick-messages/group-participants/${ticket?.whatsappId}/${ticket?.contact?.number}`);

              const filteredParticipants = data?.participants.filter(participant =>
                participant.name.toLowerCase().includes(usernameAfterLastAt.toLowerCase())
              );

              setGroupUsers(filteredParticipants);
              setIsTaggingGroupUser(true);
            }
          }, 500);

          return () => clearTimeout(timer);
        } else {
          const timer = setTimeout(async () => {
            if (isGroup) {
              const { data } = await api.get(`/quick-messages/group-participants/${ticket?.whatsappId}/${ticket?.contact?.number}`);
              setGroupUsers(data?.participants);
              setIsTaggingGroupUser(true);
            }
          }, 500);

          return () => clearTimeout(timer);
        }
      } else {
        setIsTaggingGroupUser(false);
      }
    } else {
      setIsTaggingGroupUser(false);
    }
  }, [inputMessage]);

  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  const renderPlaceholder = () => {
    if (ticketStatus === "open") {
      return i18n.t("messagesInput.placeholderOpen");
    }
    return i18n.t("messagesInput.placeholderClosed");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setInputRef = (input) => {
    if (input && !isMobile) {
      inputRef.current = input;
      input.focus();
    }
  };

  return (
    <div className={classes.messageInputWrapper}>
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        closeIcon={null}
        getOptionLabel={(option) => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value") && isNil(opt.mediaPath)) {
            setInputMessage(opt.value);
            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          } else if (isObject(opt) && has(opt, "value") && !isNil(opt.mediaPath)) {
            handleQuickAnswersClick(opt);

            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{ width: "100%" }}
        disabled={disableOption()}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              disabled={disableOption()}
              inputRef={setInputRef}
              placeholder={renderPlaceholder()}
              multiline
              className={classes.messageInput}
              maxRows={5}
            />
          );
        }}
      />
    </div>
  );
};


const MessageInputCustom = (props) => {
  const { ticketStatus, ticketId, ticket, isGroup, scrollToTop } = props;
  const classes = useStyles();
  const [quickMessageDialogOpen, setQuickMessageDialogOpen] = useState(false); // Novo estado para o modal
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const [groupUsers, setGroupUsers] = useState([]);
  const [isTaggingGroupUser, setIsTaggingGroupUser] = useState(false);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const { setEditingMessage, editingMessage } = useContext(EditMessageContext);
  const [channelType, setChannelType] = useState(null); // Estado para armazenar o tipo de canal
  const {
    selectedMessages,
    setForwardMessageModalOpen,
    showSelectMessageCheckbox
  } = useContext(ForwardMessageContext);
  const suggestionsRef = useRef(null);
  if (quickMessageDialogOpen && false) {
    console.log(quickMessageDialogOpen)
  }

  const emojiOptionsRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
        setIsTaggingGroupUser(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiOptionsRef.current && !emojiOptionsRef.current.contains(event.target)) {
        setShowEmoji(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!ticketId) return;

    const fetchChannelType = async () => {
      try {
        const { data } = await api.get(`/tickets/${ticketId}`);
        setChannelType(data.whatsapp?.type);
      } catch (err) {
        toastError(err);
      }
    };

    fetchChannelType();
    const alwaysSignActive = localStorage.getItem("alwaysSignActive");
    if (alwaysSignActive === "true") {
      setSignMessage(true);
    }
  }, [ticketId]);


  useEffect(() => {
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
      setEditingMessage(null);
    };
  }, [ticketId, setReplyingMessage, setEditingMessage]);

  const [quickMessageDialogAnchor, setQuickMessageDialogAnchor] = useState(null);

  const handleOpenQuickMessageDialog = (event) => {
    setQuickMessageDialogAnchor(event.currentTarget);
  };

  const handleCloseQuickMessageDialog = () => {
    setQuickMessageDialogAnchor(null);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
    setShowEmoji(false);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadQuickMessageMedia = async (blob, message) => {
    setLoading(true);
    try {
      const extension = blob.type.split("/")[1];
      const formData = new FormData();
      const filename = `${new Date().getTime()}.${extension}`;
      formData.append("medias", blob, filename);
      formData.append("body", message);
      formData.append("fromMe", true);

      if (channelType !== null && channelType !== "official") {
        await api.post(`/hub-message/${ticketId}`, formData);
      } else if (channelType === "official") {
        await api.post(`/official-message/${ticketId}`, formData);
      } else {
        await api.post(`/messages/${ticketId}`, formData);
      }
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleQuickAnswersClick = async (value) => {
    if (value.mediaPath) {
      try {
        const { data } = await axios.get(value.mediaPath, {
          responseType: "blob",
        });

        handleUploadQuickMessageMedia(data, value.value);
        setInputMessage("");
        return;
      } catch (err) {
        toastError(err);
      }
    }
    setInputMessage("");
    setInputMessage(value.value);
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const MAX_SIZE_MB = 20;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    let totalSize = 0;
    medias.forEach((media) => {
      totalSize += media.size;
    });

    if (totalSize > MAX_SIZE_BYTES) {
      setLoading(false);
      toastError(`O tamanho máximo  é de ${MAX_SIZE_MB}MB.`);
      return;
    }

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      if (channelType !== null && channelType !== "official") {
        await api.post(`/hub-message/${ticketId}`, formData);
      } else if (channelType === "official") {
        await api.post(`/official-message/${ticketId}`, formData);
      } else {
        await api.post(`/messages/${ticketId}`, formData);
      }
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };


  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage && !editingMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };

    try {
      if (channelType === "official") {
        // Se o canal for oficial, usar a rota de mensagem oficial
        await api.post(`/official-message/${ticketId}`, message);
      } else if (channelType !== null) {
        // Se for um canal de hub
        await api.post(`/hub-message/${ticketId}`, message);
      } else {
        // Se for um canal padrão
        await api.post(`/messages/${ticketId}`, message);
      }
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setEditingMessage(null);
  };


  const handleOpenModalForward = () => {
    if (selectedMessages.length === 0) {
      setForwardMessageModalOpen(false)
      toastError(i18n.t("messagesList.header.notMessage"));
      return;
    }
    setForwardMessageModalOpen(true);
  }
  const handleSelectQuickMessage = async (message) => {
    try {
      if (message.attachment) {
        const { data } = await axios.get(message.attachment, {
          responseType: "blob",
        });
        await handleUploadQuickMessageMedia(data, message.message);
      } else {
        setInputMessage(message.message);

        setTimeout(() => {
          handleSendMessage();
        }, 100);
      }
    } catch (err) {
      toastError(err);
    } finally {
      setQuickMessageDialogOpen(false);
    }
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      if (channelType !== null && channelType !== "official") {
        await api.post(`/hub-message/${ticketId}`, formData);
      } else if (channelType === "official") {
        await api.post(`/official-message/${ticketId}`, formData);
      } else {
        await api.post(`/messages/${ticketId}`, formData);
      }
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const disableOption = () => {
    return loading || recording || ticketStatus !== "open";
  };
  const theme = useTheme();
  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  if (medias.length > 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        <QuickMessageSelectionDialog
          open={Boolean(quickMessageDialogAnchor)}
          onClose={handleCloseQuickMessageDialog}
          onSelect={handleSelectQuickMessage}
          anchorEl={quickMessageDialogAnchor}
        />
        {replyingMessage && renderReplyingMessage(replyingMessage)}
        <div className={classes.newMessageBox}>
          {isTaggingGroupUser && (
            <div ref={suggestionsRef} style={{ width: "100%", padding: "0 1rem" }}>
              {groupUsers.map((groupUser) => (
                <div
                  key={groupUser?.id}
                  onClick={() => {
                    const inputElement = inputRef.current;

                    if (inputElement) {
                      const cursorPosition = inputElement.selectionStart;

                      const textAfterLastAt = inputMessage.slice(inputMessage.lastIndexOf('@') + 1, cursorPosition);

                      const updatedMessage =
                        inputMessage.slice(0, inputMessage.lastIndexOf('@') + 1) +
                        groupUser?.id?.split("@")[0] +
                        inputMessage.slice(cursorPosition);

                      setInputMessage(updatedMessage);

                      const newCursorPosition = cursorPosition + groupUser?.id?.split("@")[0].length - textAfterLastAt.length;
                      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
                    }

                    setIsTaggingGroupUser(false);
                  }}
                  style={{ cursor: "pointer", marginBottom: "0.5rem" }}
                >
                  {groupUser?.name}
                </div>
              ))}
            </div>
          )}

          <div className="cmp-message-box__message-container">
            <CustomInput
              loading={loading}
              inputRef={inputRef}
              ticketStatus={ticketStatus}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleSendMessage={handleSendMessage}
              handleInputPaste={handleInputPaste}
              disableOption={disableOption}
              handleQuickAnswersClick={handleQuickAnswersClick}
              replyingMessage={replyingMessage}
              editingMessage={editingMessage}
              isGroup={isGroup}
              ticket={ticket}
              groupUsers={groupUsers}
              setGroupUsers={setGroupUsers}
              setIsTaggingGroupUser={setIsTaggingGroupUser}
            />

          </div>

          <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0.5rem", opacity: theme.mode === "light" ? "100%"  : "50%"}} className="cmp-message-box__icons-container">
            <div style={{display: "flex", gap: "0.3rem"}}>
              <EmojiOptions
                ref={emojiOptionsRef}
                disabled={disableOption()}
                handleAddEmoji={handleAddEmoji}
                showEmoji={showEmoji}
                setShowEmoji={setShowEmoji}
              />


              <Tooltip title={"Mensagem rápida"}>
                <IconButton
                  aria-label="openQuickMessageDialog"
                  component="span"
                  onClick={handleOpenQuickMessageDialog}
                >
                  <ListAltIcon className={classes.sendMessageIcons} />
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="openQuickMessageDialog"
                component="span"
              >
                <SignSwitch
                  width={props.width}
                  setSignMessage={setSignMessage}
                  signMessage={signMessage}
                  disabled={disableOption()}
                />
              </IconButton>
            </div>
            <div style={{display: "flex", gap: "0.3rem"}}>
              <FileInput
                disableOption={disableOption}
                handleChangeMedias={handleChangeMedias}
              />
              <ActionButtons
                inputMessage={inputMessage}
                loading={loading}
                recording={recording}
                ticketStatus={ticketStatus}
                handleSendMessage={handleSendMessage}
                handleCancelAudio={handleCancelAudio}
                handleUploadAudio={handleUploadAudio}
                handleStartRecording={handleStartRecording}
                handleOpenModalForward={handleOpenModalForward}
                showSelectMessageCheckbox={showSelectMessageCheckbox}
              />
              <IconButton onClick={() => scrollToTop()}>
                <ArrowUpwardOutlined style={{cursor: "pointer"}} />
              </IconButton>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
};

export default withWidth()(MessageInputCustom);