import React from "react";
import { Card, CardContent, Avatar, Typography, Dialog, Button, DialogTitle, DialogContent } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        width: "100%",
        fontFamily: "Poppins",
        height: "180px", // Aumente a altura conforme necessário
    },
    avatar: {
        width: 60,
        height: 60,
        fontFamily: "Poppins",
        marginRight: theme.spacing(2),
    },
    stars: {
        display: "flex",
        fontFamily: "Poppins",
        alignItems: "center",
        marginTop: theme.spacing(1),
    },
    starIcon: {
        color: "#FFD700",
    },
    details: {
        flexGrow: 1,
        fontFamily: "Poppins",
    },
    info: {
        color: "#666",
        fontSize: "0.9rem",
    },
    button: {
        margin: theme.spacing(2),
        fontFamily: "Poppins",

    },
    listContainer: {
        display: "flex",
        fontFamily: "Poppins",
        flexDirection: "column",
        width: "100%",
    },
}));


const UserCardModal = ({ users, setOpen, open }) => {
    const classes = useStyles();

    const handleClose = () => setOpen(false);

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <h3 style={{fontFamily: "Poppins", fontSize: "16px", fontWeight: "400", padding: "1rem"}}>Usuários</h3>
                <DialogContent dividers>
                    <div className={classes.listContainer}>
                        {users.sort((a, b) => b.ticketCount - a.ticketCount).map((user) => (
                            <Card key={user.id} className={classes.card}>
                                <Avatar src={user.profilePicture ? `${process.env.REACT_APP_BACKEND_URL}${user.profilePicture}`: user.profilePicture} className={classes.avatar} alt={user.name}>
                                    {!user.profilePicture ? user.name[0] : ""}
                                </Avatar>
                                <CardContent className={classes.details}>
                                    <h4 style={{fontFamily: "Poppins", fontSize: "16px", fontWeight: "400"}} >{user.name}</h4>
                                    <div className={classes.stars}>
                                        {Array.from({ length: 3 }, (_, index) => (
                                            <Star
                                                key={index}
                                                className={classes.starIcon}
                                                style={{
                                                    opacity: index < Math.round(user.averageNPS / 2) ? 1 : 0.3,
                                                }}
                                            />
                                        ))}
                                    </div>
                                    <Typography className={classes.info}>Tickets Atendidos: {user.ticketCount}</Typography>
                                    <Typography className={classes.info}>Tempo Médio: {user.averageSupportTime || 0} min</Typography>
                                    <Typography className={classes.info}>Categorias: {user.totalCategories}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UserCardModal;
