import React, { useEffect, useRef, useState } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import ContactDetailsModal from "../ContactDetailsModal"; // ajuste o caminho conforme a estrutura do seu projeto

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import { CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@material-ui/core";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";
import useStyles from "./styles";
import { TagsContainer } from "../TagsContainer";
import BotSwitch from "../BotSwitch";
import api from "../../services/api";
import PrivacySwitch from "../BlockUserSwitch";
import truncateText from "../../utils/truncateText";
import CampaignDetailsModal from "../CampaingDetails";
import { ArrowRight, ArrowRightAlt, ArrowRightAltSharp, ArrowRightOutlined, ArrowUpwardOutlined } from "@material-ui/icons";

const initialState = {
	name: "",
	number: "",
	email: "",
	disableBot: false,
};
const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading, user }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [modalOpen, setModalOpen] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [copied, setCopied] = useState(false);
	const [contactBotInfo, setContactBot] = useState(initialState)
	const [disableBot, setDisableBot] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [whatsAppId, setWhatsAppId] = useState(0);
	const isMounted = useRef(true);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [protocolo, setProtocolo] = useState("");
	const [channelType, setChannelType] = useState(null);
	const [openCampaignModal, setOpenCampaignModal] = useState(false);
	const [protocolModal, setProtocolModalOpen] = useState(false);

	if (contactBotInfo && false) {
		console.log("");
	}

	useEffect(() => {
		if (!ticket.id) return;
		const fetchChannelType = async () => {
			try {
				const { data } = await api.get(`/tickets/${ticket.id}`);
				setChannelType(data.whatsapp?.type);
			} catch (err) {
				toastError(err);
			}
		};

		fetchChannelType();

	}, [ticket.id]);


	useEffect(() => {
		const fetchProtocolo = async () => {
			if (!ticket.id) return;

			try {
				const { data } = await api.get(`/tickets/${ticket.id}/last-traking`);
				setProtocolo(data.protocolo);
			} catch (error) {
				console.error("Erro ao buscar protocolo:", error);
			}
		};

		fetchProtocolo();
	}, [ticket]);
	const handleOpenModal = () => {
		setDetailsModalOpen(true);
	};

	const handleProtocolOpenModal = () => {
		setProtocolModalOpen(true);
	};

	const handleProtocolCloseModal = () => {
		setProtocolModalOpen(false);
	};

	const handleCloseModal = () => {
		setDetailsModalOpen(false);
	};

	const handleSendProtocolo = async () => {
		try {
			if (channelType === "official") {
				// Se o canal for oficial, usar a rota de mensagem oficial
				await api.post(`/official-message/${ticket.id}`, {
					body: `Protocolo: ${protocolo}`
				});
			} else if (channelType !== null) {
				// Se for um canal de hub
				await api.post(`/hub-message/${ticket.id}`, {
					body: `Protocolo: ${protocolo}`
				});
			} else {
				// Se for um canal padrão
				await api.post(`/messages/${ticket.id}`, {
					body: `Protocolo: ${protocolo}`
				});
			}
		} catch (err) {
			toastError(err);

		}
		handleProtocolCloseModal();
	};


	useEffect(() => {
		setOpenForm(false);
		setModalOpen(false);
	}, [open, contact]);


	useEffect(() => {
		const fetchContact = async () => {

			if (!contact?.id) return;

			try {
				const { data } = await api.get(`/contacts/${contact.id}`);
				if (isMounted.current) {
					setContactBot(data);
					setDisableBot(data.disableBot);
					setWhatsAppId(data.whatsappId);
					setIsBlocked(data.isBlocked)
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchContact();
	}, [contact, open]);

	useEffect(() => {
		setOpenForm(false);
	}, [open, contact]);

	function formatPhoneNumber(number) {
		let cleaned = ('' + number).replace(/\D/g, '');
		let match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

		if (match) {
			const firstPart = match[3].length === 5 ? `${match[3]}` : `${match[3].slice(0, 4)}-${match[3].slice(4)}`;
			return `+${match[1]} (${match[2]}) ${firstPart}-${match[4]}`;
		}

		return number;
	}

	const handleCopy = () => {
		navigator.clipboard.writeText(contact.number);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	const truncateEmail = (email) => {
		const [username, domain] = email.split("@");
		if (username.length > 7) {
			return `${username.slice(0, 7)}...@${domain}`;
		}

		return email;
	};

	const handleEmailCopy = () => {
		navigator.clipboard.writeText(contact.email).then(() => {
			alert("Email copiado!");
		});
	};

	const hideContactNumberActive = localStorage.getItem("hideContactNumberActive") === "true";

	return (
		<Drawer
			className={`${classes.drawer} cmp-tickets__mobile-drawer`}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{ paper: classes.drawerPaper + " cmp-ticket__drawer-paper" }}
		>
			<div className={classes.header}>
				<Typography style={{ justifySelf: "center", fontFamily: "Poppins", fontWeight: "400", color: theme.palette.primary.neutralTextColor }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
				<div style={{ color: theme.mode === "light" ? "#FFFFFF" : "black", background: theme.palette.primary.main }} className={"cmp-contact-drawer__close-icon"} onClick={handleDrawerClose}>
					<ArrowUpwardOutlined style={{ transform: "rotate(90deg)", }} width={"20px"} height={"20px"} />
				</div>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<CardHeader
							style={{ padding: "0", display: "flex" }}
							avatar={
								<Avatar
									src={contact.profilePicUrl}
									alt="contact_image"
									style={{ width: 60, height: 60, borderRadius: "8px" }}
								/>
							}
							title={
								<h4
									className={classes.darkTexts}
									style={{
										fontSize: "12px",
										fontWeight: "400",
										fontFamily: "Poppins",
										color: theme.palette.primary.neutralTextColor,
									}}
								>
									{contact.name}
								</h4>
							}
							subheader={
								<>
									{!(hideContactNumberActive && user.profile === "user") ? (
										<div style={{ display: "flex", alignItems: "center", fontSize: 12, cursor: "pointer", marginTop: "1rem" }}>
											<IconButton
												onClick={handleCopy}
												style={{ marginRight: "5px", fontSize: 12, padding: "0" }}
											>
												{copied ? (
													<CheckIcon style={{ color: "green", fontSize: 12 }} />
												) : (
													<FileCopyIcon style={{ color: theme.palette.primary.main, fontSize: 12 }} />
												)}
											</IconButton>
											<span style={{
												fontSize: "9px",
												fontWeight: "400",
												fontFamily: "Poppins",
												color: theme.palette.primary.neutralTextColor,
												display: "flex",
												alignItems: "center"
											}} onClick={handleCopy}>{formatPhoneNumber(contact.number)}</span>
										</div>
									) : (
										<div style={{ fontSize: 12, color: "gray" }}>Sem permissão</div>
									)}

									<Tooltip title={contact.email} arrow>
										<Typography style={{ fontSize: 12 }}>
											<Link
												href={`mailto:${contact.email}`}
												onClick={(e) => {
													e.preventDefault(); // Evita redirecionamento
													handleEmailCopy(); // Copia o email
												}}
												style={{ cursor: "pointer" }}
											>
												{truncateEmail(contact.email)}
											</Link>
										</Typography>
									</Tooltip>
								</>
							}
						/>
					</Paper>


					<ContactDetailsModal
						contactId={contact.id}
						companyId={contact.companyId}
						open={detailsModalOpen}
						onClose={handleCloseModal}

					/>
					<CampaignDetailsModal
						ticketId={ticket.id}
						open={openCampaignModal}
						onClose={() => setOpenCampaignModal(false)}
					/>
					<Paper style={{ boxShadow: "none", background: theme.palette.primary.neutralColor }}>
						<TagsContainer ticket={ticket} />
					</Paper>


					<BotSwitch contactId={contact.id} contact={contact} initialDisableBot={disableBot} />
					<PrivacySwitch contact={contact} whatsAppId={whatsAppId} isBlocked={isBlocked} />
					<>
						<Paper
							onClick={() => navigator.clipboard.writeText(`Protocolo: ${protocolo}`)}
							square
							variant="outlined"
							className={classes.contactDetails}
							style={{ padding: "0 0 1rem 0" }}
						>
							<Typography
								variant="subtitle1"
								style={{ marginBottom: 10, color: theme.palette.primary.neutralTextColor, fontWeight: "400", fontFamily: "Poppins", fontSize: "14px" }}
							>
								Protocolo:
								<span style={{ fontSize: "12px", color: `${theme.palette.primary.neutralTextColor}70` }}>
									{protocolo ? `${protocolo}` : "Carregando..."}
								</span>
							</Typography>

							<Button
								variant="outlined"
								onClick={handleProtocolOpenModal}
								style={{
									fontWeight: "400",
									border: "none",
									textTransform: "capitalize",
									borderRadius: "4px",
									marginTop: "1rem",
									fontSize: 14,
									maxWidth: "100%",
									color: "#FFFFFF",
									background: theme.palette.primary.main
								}}
							>
								{protocolo ? `Enviar` : "Carregando..."}
							</Button>
						</Paper>

						{/* Modal de Confirmação */}
						<Dialog open={protocolModal} onClose={handleProtocolCloseModal}>
							<h4 style={{fontWeight: "400", fontFamily: "Poppins",padding: "1rem", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`}}>Confirmar Envio</h4>
							<DialogContent>
								<DialogContentText>Tem certeza de que deseja enviar o protocolo?</DialogContentText>
							</DialogContent>
							<DialogActions style={{borderTop: `1px solid ${theme.palette.primary.neutralBorder}`}}>
								<Button style={{background: theme.palette.primary.main, borderRadius: "4px", color: "white", fontFamily: "Poppins", textTransform: "capitalize"}} onClick={handleProtocolCloseModal} color="secondary">Cancelar</Button>
								<Button style={{background: theme.palette.primary.main, borderRadius: "4px", color: "white", fontFamily: "Poppins", textTransform: "capitalize"}} onClick={handleSendProtocolo} color="primary" autoFocus>Confirmar</Button>
							</DialogActions>
						</Dialog>
					</>

					<Paper square variant="outlined" className={classes.contactDetails} style={{ padding: "1rem 0" }}>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
							ticket={ticket}
						></ContactModal>
						<Typography variant="subtitle1" style={{ marginBottom: 10, color: theme.palette.primary.neutralTextColor, fontWeight: "400", fontFamily: "Poppins", fontSize: "14px" }}>
							Mais informações
						</Typography>
						{contact?.extraInfo?.length > 0 ? (contact?.extraInfo?.map(info => (
							<>
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel className={classes.darkTexts} style={{ fontWeight: "700", color: theme.palette.primary.neutralTextColor, fontWeight: "400", fontFamily: "Poppins", fontSize: "12px" }}>{info.name}:</InputLabel>
									<Tooltip title={info.value}>
										<span style={{ paddingTop: 2 }} className={classes.contactExtraInfoItem}>
											{truncateText(info.value, 15)}
										</span>
									</Tooltip>
								</Paper>
							</>
						))) : <span>Nenhuma informação adicionada, procure editar o usuário.</span>}
					</Paper>

					<Paper square variant="outlined" className={classes.contactDetails}>
						<Typography variant="subtitle1" style={{ fontSize: "14px", marginBottom: 10, color: theme.palette.primary.neutralTextColor, fontWeight: "400", fontFamily: "Poppins", marginTop: "1rem" }}>
							Notas
						</Typography>
						<ContactNotes ticket={ticket} />
					</Paper>
				</div>
			)}
			<div style={{ padding: "1rem", borderTop: `1px solid ${theme.palette.primary.neutralBorder}`, background: theme.palette.primary.neutralColor }}>

				<Button
					onClick={() => setModalOpen(!openForm)}
					className={`${classes.buttonStyles}`}
					style={{ fontSize: 12, maxWidth: "100%", borderRadius: "4px", cursor: "pointer", fontWeight: "400" }}
				>
					{i18n.t("contactDrawer.buttons.edit")}
				</Button>

				<Button
					onClick={handleOpenModal}
					className={`${classes.buttonStyles}`}
					style={{ marginTop: "1rem", fontSize: 12, maxWidth: "100%", borderRadius: "4px", fontWeight: "400" }}
				>
					Detalhes do Contrato
				</Button>

				<Button
					onClick={() => setOpenCampaignModal(true)}
					className={`${classes.buttonStyles}`}
					style={{ marginTop: "1rem", fontSize: 12, maxWidth: "100%", borderRadius: "4px", fontWeight: "400" }}
				>
					Detalhes da campanha
				</Button>

				{/* Renderiza o ContactForm se necessário */}
				{contact.id && openForm && (
					<ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />
				)}
			</div>
		</Drawer>
	);
};

export default ContactDrawer;
