import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import clsx from "clsx";

import { Paper, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";  
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";

import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageProvider } from "../../context/EditingMessage/EditingMessageContext";

import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: "100vw"
  },

  mainWrapperShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRadius: "8px",
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    marginRight: 0,
  },
}));

const Ticket = ({ drawerOpen, setDrawerOpen, setMobileUserOpen, setMobileAdditionalInfoOpen }) => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    setLoading(true);
    const getAll = async (params) => {
      const { data } = await api.request({
        url: '/settings',
        method: 'GET',
        params
      });
      return data;
    }
    
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/u/" + ticketId);

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        history.push("/tickets");
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, history]);

  const handleDrawerOpen = () => {
    if (isMobile) {
      setMobileAdditionalInfoOpen(true);
      return
    }
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      setMobileAdditionalInfoOpen(false);
      return
    }
    setDrawerOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
  };
  const messagesListRef = useRef(null);

  const scrollToTop = () => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = 0;
    }
  };
  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
          user={user}
          messagesListRef={messagesListRef}
        ></MessagesList>
        <MessageInput scrollToTop={scrollToTop} ticketId={ticket.id} ticketStatus={ticket.status} ticket={ticket} isGroup={ticket.isGroup}/>
      </>
    );
  };

  return (
    <div className={`${classes.root} ${!drawerOpen ? "cmp-drawer--closed" : ""}`} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {renderTicketInfo()}
          <TicketActionButtons setMobileAdditionalInfoOpen={setMobileAdditionalInfoOpen} setMobileUserOpen={setMobileUserOpen} contact={contact} handleDrawerOpen={handleDrawerOpen} drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} ticket={ticket} />
        </TicketHeader>
        <ReplyMessageProvider>
          <EditMessageProvider>
            {renderMessagesList()}
          </EditMessageProvider>
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        user={user}
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
        ticket={ticket}
      />
    </div >
  );
};

export default Ticket;
