import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik, Form, Field } from "formik";

import ContactNotesDialogListItem from '../ContactNotesDialogListItem';
import ConfirmationModal from '../ConfirmationModal';

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";

import ButtonWithSpinner from '../ButtonWithSpinner';

import useTicketNotes from '../../hooks/useTicketNotes';
import { Button, Grid } from '@material-ui/core';
import { BorderBottom } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '350px',
            fontFamily: "Poppins",

        },
        '& span, & p, & label': {
            fontFamily: "Poppins",
        },
        ...theme.scrollbarStyles
    },
    list: {
        width: '100%',
        maxWidth: '350px',
        maxHeight: '200px',
        fontFamily: "Poppins",
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        ...theme.scrollbarStyles
    },
    inline: {
        fontFamily: "Poppins",
        width: '100%'
    }
}));

export function ContactNotes({ ticket }) {
    const { id: ticketId, contactId } = ticket
    const classes = useStyles()
    const [newNote, setNewNote] = useState({ note: "" });
    const [loading, setLoading] = useState(false)
    const [showOnDeleteDialog, setShowOnDeleteDialog] = useState(false)
    const [selectedNote, setSelectedNote] = useState({})
    const [notes, setNotes] = useState([])
    const { saveNote, deleteNote, listNotes } = useTicketNotes()

    useEffect(() => {
        async function openAndFetchData() {
            handleResetState()
            await loadNotes()
        }
        openAndFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleResetState = () => {
        setNewNote({ note: "" })
        setLoading(false)
    }

    const handleChangeComment = (e) => {
        setNewNote({ note: e.target.value })
    }

    const handleSave = async values => {
        setLoading(true)
        try {
            await saveNote({
                ...values,
                ticketId,
                contactId
            })
            await loadNotes()
            setNewNote({ note: '' })
            toast.success('Observação adicionada com sucesso!')
        } catch (e) {
            toast.error(e)
        }
        setLoading(false)
    }

    const handleOpenDialogDelete = (item) => {
        setSelectedNote(item)
        setShowOnDeleteDialog(true)
    }

    const handleDelete = async () => {
        setLoading(true)
        try {
            await deleteNote(selectedNote.id)
            await loadNotes()
            setSelectedNote({})
            toast.success('Observação excluída com sucesso!')
        } catch (e) {
            toast.error(e)
        }
        setLoading(false)
    }

    const loadNotes = async () => {
        setLoading(true)
        try {
            const notes = await listNotes({ ticketId, contactId })
            setNotes(notes)
        } catch (e) {
            toast.error(e)
        }
        setLoading(false)
    }
    const theme = useTheme();
    return (
        <>
            <ConfirmationModal
                title="Excluir Registro"
                open={showOnDeleteDialog}
                onClose={setShowOnDeleteDialog}
                onConfirm={handleDelete}
            >
                Deseja realmente excluir este registro?
            </ConfirmationModal>
            <Formik
                initialValues={newNote}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSave(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >

                {({ touched, errors, setErrors }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <Field
                                    as={TextField}
                                    name="note"
                                    rows={3}
                                    label={"Notas"}
                                    placeholder={"Digite sua nota"}
                                    multiline={true}
                                    error={touched.note && Boolean(errors.note)}
                                    helperText={touched.note && errors.note}
                                    variant="outlined"
                                    onChange={handleChangeComment}
                                    fullWidth
                                    style={{ fonftFamily: "Poppins", borderRadius: "4px 4px 0 0", BorderBottom: "none" }}
                                />
                            </Grid>


                        </Grid>
                        <Button style={{ background: theme.palette.primary.main, fontFamily: "Poppins", textTransform: "capitalize", borderRadius: "0 0 4px 4px", color: "#FFFFFF", fontWeight: "400", fontSize: "14px" }} disabled={!newNote.note.length ? true : false} loading={loading} type="submit" fullWidth>
                            Adicionar
                        </Button>
                        {notes.length > 0 && (
                            <Grid xs={12} item>
                                <List className={classes.list}>
                                    {
                                        notes.map((note) => {
                                            return <ContactNotesDialogListItem
                                                note={note}
                                                key={note.id}
                                                deleteItem={handleOpenDialogDelete}
                                            />
                                        })
                                    }
                                </List>
                            </Grid>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
}